import stringSimilarity from 'string-similarity';
import { format } from 'date-fns';
import { updateRVUand$Value } from '../../../../utils/auditsheet.utils';


const FILE_HEADER_MAPPING = {
    "encounter_no": ['Chart #'],
    "rendering": ['Physician Name'],
    "enc_dt": ['Date'],
    "response": ['CPTCoding Rationale'],
    "billed_icd": ['ICD'],
    "srvcs_no": ['CPT'],

}

const REQUIRED_FIELDS = {
    "missing_info": null,
    "encounter_no": null,
    "rendering": null,
    "enc_dt": null,
    "billed_icd": null,
    "srvcs_no": null,
    "billed_modifier": null,
    "provider_rvu": null,
    "provider_dollar_value": null,
    "response": null,
    "agree": null,
    "disagree": null,
    "icd_agree": null,
    "icd_disagree": null,
    "audited_icd": null,
    "audited_cpt": null,
    "audited_modifier": null,
    "audited_rvu": null,
    "audited_dollar_value": null,
    "notes": null,
    "provider_education": null,
    "number_complexity": null,
    "amount_complexity": null,
    "risk_complications": null,
    "file_name": null,
    "chart_id": null,
    "row_id": null,
    "id": null
}

export const processSheedData = (excelData, providerOptions, currentUpload, industryCodes) => {
    let result = {
        "index": 1,
        "sheet_name": "Sheet1",
        "data": []
    };
    validateExcelHeaders(excelData);
    result.data = excelData.map(row => {
        let mappedRow = REQUIRED_FIELDS;
        Object.keys(FILE_HEADER_MAPPING).forEach(key => {
            
            mappedRow['chart_id'] = currentUpload.id;
            mappedRow['file_name'] = currentUpload.upload_id;

            if (key === "billed_icd") {
                mappedRow = {...mappedRow, ...mapICDAndCPT(row, "billed_icd", 'ICD')}; // Map ICD dynamically
            } else if (key === "srvcs_no") {
                mappedRow = {...mappedRow, ...mapICDAndCPT(row, "srvcs_no", 'CPT')}; // Map CPT dynamically
            } else {
                // For other keys, map as normal
                const data = FILE_HEADER_MAPPING[key]
                    .map(h => row[h])
                    .find(value => value !== undefined);
                if (data !== undefined) {
                    if(key === "rendering"){
                        mappedRow[key] = getProperPhysicianName(data, providerOptions);
                    } else if(key === "enc_dt"){
                        mappedRow[key] = formatDate(data);
                    } else if(key === "response"){
                        mappedRow[key] = data.slice(0, 200);
                    }else{
                        mappedRow[key] = data;
                    }
                }
            }
        });
        // final_data.data = {...final_data.data, ...result};
        
        return mappedRow;
    });
    result.data = calculate$Value(result, industryCodes);
    return result;   
}

const calculate$Value = (result, industryCodes) => {
    let processdata = result.data;
    processdata.forEach((value, index) => {
        processdata = updateRVUand$Value(processdata, index, industryCodes);
    });
    return processdata;
}

// Function to handle dynamic numbering for ICD and CPT headers
const mapICDAndCPT = (row, key, header) => {
    let count = 1; // Start with 0 for ICD1 or CPT1
    // Look for keys that match the header pattern (e.g., ICD1, ICD2, CPT1, CPT2)
    let data = {};
    Object.keys(row).forEach(headerKey => {
        if (headerKey.startsWith(header) && headerKey !== 'CPTCoding Rationale') {
            const assignValues = (suffix = '') => {
                if (key === "srvcs_no") {
                    const cpt_split = parseInput(row[headerKey]);
                    data[`billed_modifier${suffix}`] = cpt_split.mods;
                    data[`${key}${suffix}`] = cpt_split.codeUnit;
                } else {
                    data[`${key}${suffix}`] = row[headerKey];
                }
            };
    
            count === 1 ? assignValues() : assignValues(`_${count}`);
            count++;
        }
    });    
    return data
};


const getProperPhysicianName = (sheetName, providerOptions) => {
    if (sheetName && providerOptions.length > 0) {
        const match = stringSimilarity.findBestMatch(sheetName.toLowerCase(),  providerOptions.map(obj => obj.value.toLowerCase()));
       
        if (match.bestMatch.rating > 0.6) {
            // Get the original object that corresponds to the matched string
            const bestMatchObject = providerOptions.find(obj => obj.value.toLowerCase() === match.bestMatch.target);
            return bestMatchObject.value;
        } else {
            return null;
        }
    }
    return null;
}

const formatDate = (dateStr) => {
    const parsedDate = new Date(dateStr);
    if (isNaN(parsedDate.getTime())) {
        return null;
    }
    return format(parsedDate, 'MM-dd-yyyy');
};




const parseInput = (input) => {
    if (!input) return { codeUnit: '', mods: '' }; // Handle empty input gracefully

    const [codeAndMods, unitAndMods] = input.split('*'); // Split by '*'
    let code = '';
    let unit = '';
    let mods = '';

    // If there's only `code` and no '*', handle it
    if (!unitAndMods) {
        [code, ...mods] = codeAndMods.split('-');
        mods = mods.join(',');
        return { codeUnit: code, mods };
    }

    // If both parts exist, extract `code` and `unit`
    [code, ...mods] = codeAndMods.split('-');
    unit = unitAndMods.split('-')[0]; // Unit comes before `-`
    mods = [...mods, ...unitAndMods.split('-').slice(1)].join(',');

    // Construct result
    return {
        codeUnit: unit ? `${code}*${unit}` : code,
        mods
    };
};



function validateExcelHeaders(excelData) {
    if (!Array.isArray(excelData) || excelData.length === 0) {
        throw new Error("Invalid Excel data: no rows found.");
    }

    const firstRow = excelData[0];
    if (typeof firstRow !== 'object') {
        throw new Error("Invalid Excel data: rows should be objects.");
    }
    const firstRowHeaders = Object.keys(firstRow);
    for (const [key, requiredHeaders] of Object.entries(FILE_HEADER_MAPPING)) {
        const isHeaderPresent = requiredHeaders.some(requiredHeader =>
            firstRowHeaders.some(actualHeader =>
                actualHeader.toLowerCase().includes(requiredHeader.toLowerCase())
            )
        );
        if (!isHeaderPresent) {
            throw new Error(`Invalid file: Missing required header`);
        }
    }
}
