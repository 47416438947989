import React, { useState, useContext, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Progress, Box, VStack, UnorderedList, ListItem, Alert, AlertIcon } from '@chakra-ui/react';
import { FaFileUpload } from 'react-icons/fa'; // For button icon
import { processSheedData } from './process_sheet_data';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import WorkerBuilder from "./workBulider";


function ExcelReader({ updateAuditSheet, providerOptions, currentUpload }) {
    const auditSheetContext = useContext(AuditSheetContext);
    const { industryCodes } = auditSheetContext;
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [error, setError] = useState(null);
    const [progressMessage, setProgressMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [worker, setWorker] = useState(null);
    const [processedData, setProcessedData] = useState(null);
    const openModal = () => setIsOpen(true);
    const closeModal = () => {
        setIsOpen(false);
        resetState();
    };

    const resetState = () => {
        setIsLoading(false);
        setIsImported(false);
        setError(null);
        setProgressMessage('');
        setProgress(0);
        setFileName('');
    };

    useEffect(() => {
        if(processedData){
            updateAuditSheet(processedData)
            setProgressMessage('File has been successfully imported!');
            setIsImported(true);
        }
    }, [processedData]);
   
    useEffect(() => {
        const myworker = new WorkerBuilder();
        myworker.onmessage = async (e) => {
            const { success, json, error, context } = e.data;
            if (!success) {
                setError(error || 'Error while processing the file.');
                setProgress(0);
                setIsLoading(false);
                myworker.terminate();
                return;
            }

            setProgress(50);  // Halfway done

            try {
                const processedData = processSheedData(json, context.providerOptions, context.currentUpload, context.industryCodes);
                setProgress(75);
                
                setProcessedData(processedData);
                setProgress(90);
                setProgressMessage('Updating backend.');
            } catch (err) {
                setError(err.message || 'Error during import.');
                setProgress(0);
            } finally {
                setIsLoading(false);
                myworker.terminate();
            }
            
        };

        myworker.onerror = (e) => {
            setError('Error in processing worker.');
            setProgress(0);
            setIsLoading(false);
            worker.terminate();
        };
        setWorker(myworker);
    }, []);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFileName(file.name);
        setIsLoading(true);
        setProgress(10);
        setProgressMessage('Please wait processing...');
        
        worker.postMessage({ file, context: {providerOptions, currentUpload, industryCodes} });
        
    };

    return (
        <>
            <Button
                colorScheme="teal"
                leftIcon={<FaFileUpload />}
                onClick={openModal}
                isDisabled={isLoading}
                variant="solid"
                size="md"
                boxShadow="md"
                _hover={{ boxShadow: 'lg', bg: 'teal.600' }}
            >
                {isLoading ? 'Uploading...' : 'Import Sheet'}
            </Button>

            <Modal isOpen={isOpen} onClose={closeModal} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Import Excel File</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isLoading ? (
                            <VStack spacing={4} align="center">
                                <Text fontSize="lg" fontWeight="medium">{progressMessage}</Text>
                                <Progress value={progress} size="xs" colorScheme="teal" width="100%" />
                                <Text fontSize="sm" color="gray.500">{fileName || 'No file selected'}</Text>
                            </VStack>
                        ) : isImported ? (
                            <Box textAlign="center">
                                <Text fontSize="lg" color="green.500" fontWeight="bold">File successfully imported!</Text>
                                <Text mt={2} fontSize="md">You can now proceed with the next steps.</Text>
                            </Box>
                        ) : error ? (
                            <Box textAlign="center">
                                <Text color="red.500" fontSize="lg" fontWeight="medium">{error}</Text>
                                <Text mt={2}>Please ensure the file is in the correct format and try again.</Text>
                            </Box>
                        ) : (
                            <VStack spacing={4} align="center">
                                <Text fontSize="md">Select an Excel file to import</Text>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                />
                                <label htmlFor="file-upload">
                                    <Button
                                        as="span"
                                        colorScheme="blue"
                                        leftIcon={<FaFileUpload />}
                                        size="md"
                                        cursor="pointer"
                                        _hover={{ bg: 'blue.600' }}
                                    >
                                        Browse Files
                                    </Button>
                                </label>
                                <Text fontSize="sm" color="gray.500">{fileName || 'No file selected'}</Text>
                            </VStack>
                        )}
                        <Box>
                            <Alert status="warning" fontSize="sm" mb={2}>
                                <AlertIcon />
                                Please upload the Excel sheet only if it contains the following headers:
                            </Alert>
                            <UnorderedList fontSize="sm" pl={3}>
                                <ListItem>Chart #</ListItem>
                                <ListItem>Physician Name</ListItem>
                                <ListItem>Date</ListItem>
                                <ListItem>ICD 1, ICD 2...</ListItem>
                                <ListItem>CPT 1, CPT 2...</ListItem>
                                <ListItem>CPT Coding Rationale</ListItem>
                            </UnorderedList>
                            <Text fontSize="sm" color="red.600">
                                Use this process only at the start, as it will remove all existing data.
                            </Text>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {isImported && (
                            <Button colorScheme="blue" onClick={closeModal} width="100%">
                                Close
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ExcelReader;
