/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import AuditSheet from './components/AuditSheet';
import AuditSheetFooter from './components/AuditSheetFooter';
import Topbar from './components/Topbar';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';
import { withProvider } from '../../hoc/withProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuditSheetProvider, {
  AuditSheetContext,
} from '../../providers/AuditSheetProvider';
import { createEmptyObject } from './components/createEmptyObject';
import { AUDITOR, AWAITING_AUDIT } from '../../constants/constants';
import { sanitize } from './components/sanitize';
import SubmitModal from './components/SubmitModal';
import ROUTES from '../../constants/Routes';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { successToast } from '../../components/successToast';
import { submitRule } from './components/submitRules';
import CommentBox from './components/CommentBox';
import html2canvas from 'html2canvas';
import {
  arrangeData,
  createEmptyColumn,
  payloadreStructure,
  prepareAuditSheetColumn,
  prepareAuditSheetHeader,
  prepareUpdatePayload,
} from '../../utils/auditsheet.utils';

const AuditSheetContainer = ({ view }) => {
  const auditSheetCount = Math.ceil(window.innerHeight / 24);

  // refs
  const domEl = useRef(null);
  const topbar = useRef(null);
  const footer = useRef(null);

  // other hooks
  const { uploadId } = useParams();
  const navigate = useNavigate();
  const toast = useToastr();

  const [isOpen, ioState] = useBoolean(false);
  const [isLoading, ilState] = useBoolean(false);
  const [isCommentOpen, icoState] = useBoolean(false);
  const [rebuttalChecked, rcState] = useBoolean(true);

  const [lastSaved, setLastSaved] = useState('');
  const [sheets, setSheets] = useState([
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: createEmptyObject(auditSheetCount),
    },
  ]);
  const [activeSheetIndex, setActiveSheetIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [metaData, setMetaData] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [coords, setCoords] = useState({
    x: 0,
    y: 0,
  });

  const auditSheetContext = useContext(AuditSheetContext);

  const { getAuditUpload, getAuditSheet, currentUpload } = auditSheetContext;
  const { getIndustryCodes, getUsers, users } = auditSheetContext;
  const { getAllUsers, getCommentFlag, providerOptions } = auditSheetContext;

  const sheetNames = sheets.map(s => s?.sheet_name);

  // get auditsheet user data
  const getUpload = async () => {
    if (uploadId) {
      const res = await getAuditUpload(uploadId);
      setLastSaved(res.updated_date);
    }
  };

  const onCommentClick = (selection, dimension, comment, canRebuttal) => {
    setMetaData({ ...dimension, selection, canRebuttal });
    comment && icoState.on();
  };

  const onZoom = value => {
    let temp = 1;
    if (value >= 50) {
      temp = (2 * value) / 100;
    } else if (value === 0) {
      temp = 0.3;
    } else {
      temp = (value / 100) * 2;
    }
    setScale(temp);
  };

  // sheets Data || fetch data
  const getSheet = async () => {
    if (
      uploadId &&
      Object.keys(currentUpload).length 
      //&& currentUpload.status !== AWAITING_AUDIT
    ) {
      const response = await getAuditSheet(uploadId, currentUpload);

      if (response.length) {
        const updated = response.map((res, i) => {
          const _reDestructuredData = createEmptyColumn(res.data);

          const reDestructuredData = _reDestructuredData.map(sd => {
            const tempObj = { ...sd, ...sd.additional_attributes };
            tempObj['audited_cpt'] = tempObj['audited_code'];
            delete tempObj.additional_attributes;
            delete tempObj?.audited_code;

            return tempObj;
          });

          const updatedData = reDestructuredData.map(datum => {
            if (datum.enc_dt) {
              const parts = datum.enc_dt.split('-');
              const year = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1;
              const day = parseInt(parts[2], 10);
              const parsedDate = new Date(year, month, day);

              if (!isNaN(parsedDate.getTime())) {
                const formattedDate = `${(parsedDate.getMonth() + 1)
                  .toString()
                  .padStart(2, '0')}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, '0')}-${parsedDate.getFullYear()}`;
                datum.enc_dt = formattedDate;
              }
            }
            if (!datum.hasOwnProperty('icd_agree')){
              datum['icd_agree'] = null
            }
            if (!datum.hasOwnProperty('icd_disagree')){
              datum['icd_disagree'] = null
            }
            return datum;
          });

          const _arrangedata = arrangeData(updatedData);

          const updatedDataWithEmptyObjects = [
            ..._arrangedata,
            ...createEmptyObject(auditSheetCount - updatedData.length),
          ];

          return {
            ...res,
            index: i + 1,
            data: updatedDataWithEmptyObjects,
          };
        });
        setSheets(updated);
      }
    }
  };

  useEffect(() => {
    getUpload();
    getIndustryCodes();
    getUsers();
    getAllUsers();
    auditSheetContext.fetchProviderDropdown(uploadId);
  }, []);

  useEffect(() => {
    if (uploadId) {
      getCommentFlag(uploadId);
    }
  }, [uploadId]);

  useEffect(() => {
    getSheet();
  }, [currentUpload, providerOptions]);

  useEffect(() => {
    /**
     * sanitize() will add old_rendering and asign as id to rendering and remove empty rows
     */

    const sanitizedSheet = sanitize(sheets, providerOptions);

    disableSubmitFunc(sanitizedSheet);
  }, [sheets]);

  const disableSubmitFunc = sanitizedSheet => {
    const isEverySanitizedSheetEmpty = sanitizedSheet.every(
      sheet => sheet.data.length === 0
    );

    if (isEverySanitizedSheetEmpty) {
      setSubmitDisabled(true);
    } else {
      const isSubmitable = submitRule(sanitizedSheet);
      setSubmitDisabled(isSubmitable);
    }
  };

  const downloadImage = async () => {
    // download image
    function dataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',');
      const byteString =
        splitDataURI[0].indexOf('base64') >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    }
    const canvas = await html2canvas(domEl.current);
    const dataUrl = canvas.toDataURL('image/png', 1.0);
    return dataURIToBlob(dataUrl);
  };

  const onAuditSubmit = async () => {
    // call audithour
    await auditSheetContext.monitorAuditHour(uploadId);
    // create audit sheet
    const sanitizedSheet = sanitize(sheets, providerOptions);

    // const payload = payloadreStructure(sanitizedSheet);
    const payload = prepareUpdatePayload(sanitizedSheet);

    const response = await auditSheetContext.updateAuditSheet(
      payload,
      uploadId
    );
    //submit screenshot
    const file = await downloadImage();
    const fd = new FormData();
    fd.append('image', file);
    await auditSheetContext.recentAuditImageUpload(uploadId, fd);

    if (response) {
      ioState.off();
      navigate(ROUTES.DASHBOARD);
      const toastProps = {
        header: 'Success!',
        description: `${'Audit Submitted for Review.'}`,
      };
      successToast(toast, toastProps);
    }
  };

  const updateAuditSheet = async sheetParams => {
    let sanitizedSheet = [];
    
    if (sheetParams) {
      sheets[activeSheetIndex] = sheetParams;
      sanitizedSheet = sanitize(sheets, providerOptions);
    } else {
      sanitizedSheet = sanitize(sheets, providerOptions);
    }
    try {
      // const reStructurePayload = payloadreStructure(sanitizedSheet);
      const payload = prepareUpdatePayload(sanitizedSheet);

      await auditSheetContext.createAuditSheet(payload, uploadId);
      await getSheet();
      setLastSaved(new Date());
    } catch (err) {
      // console.log({ err })
    }
  };

  const addNewSheet = async () => {
    const updatedSheets = [
      ...sheets,
      {
        index: sheets.length + 1,
        data: createEmptyObject(auditSheetCount),
        sheet_name: `Sheet${sheets.length + 1}`,
      },
    ];
    setSheets([...updatedSheets]);
    setActiveSheetIndex(sheets.length);
  };

  const changeSheetName = async (index, name) => {
    sheets[index] = { ...sheets[index], sheet_name: name };
    setSheets([...sheets]);
    const sanitizedSheet = sanitize(sheets, providerOptions);
    await auditSheetContext.createAuditSheet(sanitizedSheet, uploadId);
  };

  const onLeftNavigate = () => {
    if (activeSheetIndex > 0) {
      setActiveSheetIndex(activeSheetIndex - 1);
    }
  };

  const onRightNavigate = () => {
    if (activeSheetIndex < sheets.length - 1) {
      setActiveSheetIndex(activeSheetIndex + 1);
    }
  };

  const updateSheet = sheetData => {
    sheets[activeSheetIndex].data = sheetData;
    setSheets([...sheets]);
  };

  const removeRows = rowIndexes => {
    const data = sheets[activeSheetIndex].data.map((obj, i) =>
      rowIndexes.includes(i) ? { id: null } : obj
    );
    sheets[activeSheetIndex].data = data;
    setSheets([...sheets]);
  };

  const onDeleteSheet = async index => {
    if (sheets.length > 1) {
      let sheetIndex = 1;
      let newSheet = [];
      sheets.forEach((p, i) => {
        if (index !== i) {
          newSheet.push({ ...p, index: sheetIndex });
          sheetIndex = sheetIndex + 1;
        }
      });
      setSheets([...newSheet]);
      if (activeSheetIndex === index && index > 0) {
        const data = activeSheetIndex - 1;
        setActiveSheetIndex(data);
      }
      const sanitizedSheet = sanitize(newSheet, providerOptions);
      await auditSheetContext.createAuditSheet(sanitizedSheet, uploadId);
    }
  };

  const onOpenNotes = obj => {
    if (obj && Object.keys(obj).length) {
      if (obj.all) {
        delete auditSheetContext.commentFilters.sheet_name;
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          page: 1,
        });
      } else if (obj.current) {
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          sheet_name: sheets[activeSheetIndex].sheet_name,
          page: 1,
        });
      }
    } else {
      auditSheetContext.setCommentFilters({
        ...auditSheetContext.commentFilters,
        id: currentUpload.id,
        sheet_name: sheets[activeSheetIndex].sheet_name,
        reverse: true,
        page: 1,
      });
    }
  };

  function handleSheetClick(e) {
    setCoords({
      x:e.clientX,
      y:e.clientY
    })
  }

  function renderTopBar() {
    return (
      <Box w={'100%'} ref={topbar}>
        <Topbar
          onAuditSubmit={() => ioState.on()}
          updateAuditSheet={async params => {
            updateAuditSheet(params);
            //submit screenshot
            const file = await downloadImage();
            const fd = new FormData();
            fd.append('image', file);
            await auditSheetContext.recentAuditImageUpload(uploadId, fd);
          }}
          view={view}
          disableSubmit={isSubmitDisabled}
          lastSaved={lastSaved}
          onChange={on => {
            if (on) {
              ilState.on();
            } else {
              ilState.off();
            }
          }}
          onOpenNotes={onOpenNotes}
        />
      </Box>
    );
  }

  function renderAuditSheet() {
    return (
      <Box id="domEl" ref={domEl} onClick={handleSheetClick} >
        <AuditSheet
          height={
            window.innerHeight -
            topbar?.current?.clientHeight -
            footer?.current?.clientHeight
          }
          scale={scale}
          sheet={sheets[activeSheetIndex]}
          uploadId={uploadId}
          view={view}
          updateAuditSheet={updateAuditSheet}
          updateSheet={updateSheet}
          onCommentClick={onCommentClick}
          removeRows={removeRows}
          closeComment={() => {
            icoState.off();
            rcState.on();
          }}
        />
      </Box>
    );
  }

  function renderFooter() {
    return (
      <Box w={'100%'} position={'fixed'} bottom={0} ref={footer}>
        <AuditSheetFooter
          onSheetChange={setActiveSheetIndex}
          sheets={sheetNames}
          activeSheetIndex={activeSheetIndex}
          addNewSheet={addNewSheet}
          changeSheetName={changeSheetName}
          onLeftNavigate={onLeftNavigate}
          onRightNavigate={onRightNavigate}
          onZoom={onZoom}
          onDeleteSheet={onDeleteSheet}
          //
          providerOptions={providerOptions}
          currentUpload={currentUpload}
          updateAuditSheet={updateAuditSheet}
          view={view}

        />
      </Box>
    );
  }

  function renderSubmitModal() {
    return (
      <SubmitModal
        isOpen={isOpen}
        onClose={() => ioState.off()}
        onAuditSubmit={onAuditSubmit}
      />
    );
  }

  function renderModal() {
    return (
      <Modal size={'sm'} isOpen={isLoading} isCentered bgColor={'transparent'}>
        <ModalOverlay style={{ backgroundColor: AppColors.lightSkyBlue }} />
        <ModalContent bgColor={'transparent'} shadow={'none'}>
          <ModalBody style={{ alignSelf: 'center' }}>
            <Spinner />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  function renderCommentBox() {
    return (
      <CommentBox
        showMenu={isCommentOpen}
        role={AUDITOR}
        metaData={metaData}
        currentUpload={currentUpload}
        closeComment={() => {
          icoState.off();
          rcState.on();
        }}
        users={users}
        sheet={sheets[activeSheetIndex]}
        isSelected={rebuttalChecked}
        onRebuttalCheck={flag => {
          if (flag) {
            rcState.on();
          } else {
            rcState.off();
          }
        }}
        coords={coords}
      />
    );
  }
  
  return (
    <Box h={'100vh'} bgColor={AppColors.bgColor}>
      {renderTopBar()}

      {renderAuditSheet()}

      {renderFooter()}

      {renderSubmitModal()}

      {renderModal()}

      {renderCommentBox()}
    </Box>
  );
};
export default withProvider(AuditSheetProvider, AuditSheetContainer);
