export default class WorkerBuilder extends Worker {
    constructor() {
      const workerScript = `
      importScripts("https://cdn.sheetjs.com/xlsx-0.20.3/package/dist/xlsx.full.min.js");
      onmessage = function (e) {
        const file = e.data.file;
        const context = e.data.context
        if (!file) {
            postMessage({ success: false, error: 'No file provided' });
            return;
        }
        
        try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
          postMessage({ success: true, json, context });
        };
        
        reader.onerror = function() {
          postMessage({ success: false, error: 'FileReader error occurred' });
        };
        
        reader.readAsArrayBuffer(file);
      } catch (error) {
        postMessage({ success: false, error: error.message });
      }
   
    };
    `;
      const blob = new Blob([workerScript]);
      return new Worker(URL.createObjectURL(blob));
    }
  }